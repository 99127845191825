<template>
    <div class="pb-20 pt-20">
        <div class="container mx-auto grid grid-cols-1 p-3 sm:w-full md:w-4/12">

            <form @submit.prevent="updatePassword" method="POST">

                <div class="bg-white p-3 rounded-md shadow-md">
                    <div class="grid grid-cols-1 gap-4">

                        <div class="mb-2">
                            <label class="mt-2 ml-2 text-xs">
                                Password Baru
                            </label>
                            <input type="password"
                                class="appearance-none w-full bg-gray-100 border border-gray-100 rounded h-15 shadow-sm placeholder-gray-400 focus:outline-none focus:placeholder-gray-400 focus:bg-white focus-within:text-gray-600 p-2 font-medium text-sm"
                                placeholder="Password Anda" v-model="user.password">
                        </div>

                        <div class="mb-2">
                            <label class="mt-2 ml-2 text-xs">
                                Konfirmasi Password Baru
                            </label>
                            <input type="password"
                                class="appearance-none w-full bg-gray-100 border border-gray-100 rounded h-15 shadow-sm placeholder-gray-400 focus:outline-none focus:placeholder-gray-400 focus:bg-white focus-within:text-gray-600 p-2 font-medium text-sm"
                                placeholder="Konfirmasi Password Anda" v-model="user.password_confirmation">
                        </div>

                        <div class="mt-5">
                            <button
                                type="submit"
                                class="bg-yellow-500 py-1.5 rounded-md shadow-md text-lg w-full uppercase font-bold text-white focus:outline-none focus:bg-yellow-600">
                                UPDATE PASSWORD
                            </button>
                        </div>

                    </div>
                </div>

            </form>

        </div>
    </div>
</template>

<script>

    //hook vue
    import { reactive, ref } from 'vue'
    
    //hook vuex
    import { useStore } from 'vuex'

    //hook vue router
    import { useRouter } from 'vue-router'
    
    //hook Toast
    import { useToast } from "vue-toastification"

    export default {

        setup() {

            //store vuex
            const store = useStore()

            //route
            const router = useRouter()

            //same interface as this.$toast
            const toast = useToast()

            //state user
            const user = reactive({
                password: '',
                password_confirmation: ''
            })

            //validation state
            const validation = ref([])

            //method update password
            function updatePassword() {

                let password = user.password
                let password_confirmation = user.password_confirmation

                //panggil actions "updatePassword" dari module "profile"
                store.dispatch('profile/updatePassword', {
                    password,
                    password_confirmation
                })
                .then(() => {

                    router.push({name: 'dashboard'})

                    toast.success("Password Berhasil Diupdate!")

                }).catch(error => {
                    //assign validaation message
                    validation.value = error

                    //show validation password with toast
                    if(validation.value.password) {
                        toast.error(`${validation.value.password[0]}`)
                    }
                })

            }

            return {
                user,              // <-- state password
                validation,         // <-- state validation
                updatePassword,     // <-- method updateProfile
            }

        }


    }
</script>

<style>

</style>